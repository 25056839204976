import React from "react"

import Layout from "../components/layout"


const PrivacyPage = () => (
  <Layout title="Palvelut">

    <div className="mx-auto col-xl-5 col-lg-6 col-md-7 col-sm-8 col-xs-12">

        <h2> Tietosuoja (GDPR) </h2>

        <p>Tietosuojaseloste</p>

        <h3> 1. Yleistä </h3>
        <p> Aisto arvostaa asiakkaidensa sekä sidosryhmiensä yksityisyyttä ja on sitoutunut suojaamaan niitä EU:n tietosuoja-asetusta noudattaen. </p>

        <p> Kaikki antamasi asiakastiedot ovat luottamuksellisia. </p>

        <p> Tällä tietosuojaselosteella informoimme asiakkaitamme ja sidosryhmiämme heidän henkilötietojensa käsittelystä. Halutessasi lisätietoa tietosuojakäytännöistämme tai henkilötietojen käsittelystä, vastaamme mielellämme kysymyksiinne. Yhteystietomme löydät täältä http://www.aisto.fi/contact. </p>

        <h3> 2. Rekisterinpitäjä ja yhteystiedot </h3>
        <p> Käsiteltävien henkilötietojen rekisterinpitäjänä toimii: </p>
        <p> Juuso Lahtinen / Aisto </p>
        <p> Y-tunnus: 3007015-7 </p>
        <p> juuso.lahtinen@aisto.fi </p>
        <p> Puh. +358458925228 </p>

        <h3> 3. Mitä tietoja kerätään </h3>

        <ul>
            <li> Tunnistamistiedot, kuten nimi</li>
            <li> Yhteystiedot, kuten postiosoite, sähköpostiosoite tai puhelinnumero</li>
            <li> Palvelu- sekä toimitussopimusten tiedot, kuten yrityksen päättäjien tiedot, yhteyshenkilöt ja näiden yhteystiedot</li>
        </ul>
        <p>Yllä mainittujen tietojen antaminen on pakollista asioidaksesi Aiston kanssayhteydenottolomakkeen välityksellä ja käyttääksesi tarjoamiamme palveluita. Tietojen pääasiallinen lähde on käyttäjän itsensä antamat tiedot, minkä lisäksi saatamme saada lisätietoja yhteistyökumppaneiltamme, yleisesti saatavilla olevista internetlähteistä tai muista saatavilla olevista julkisista lähteistä.</p>

        <h3> 4. Mihin tietojani käytetään </h3>
        <p>Henkilötietoja käytetään:</p>
        <ul>
            <li> Myynnin- ja kumppanuuksien hallintaan sekä asiakassuhteiden ylläpitoon</li>
            <li> Tuotteiden ja palveluiden toimittamiseen</li>
        </ul>
        <p>
        Tietoja käsitellään asiakkaan ja Aiston väliseen asiakassuhteeseen, palveluiden ja tuotteiden tuottamiseen sekä toimittamiseen asiakkaan erilliseen nimenomaiseen suostumukseen tai lainsäädännön velvoitteisiin perustuen.
        </p>
        <p>
        Henkilötietoja käsitellään tarkoituksissa, jotka liittyvät asiakassuhteen hoitamiseen, hallinnointiin ja kehittämiseen, sekä palvelujen tarjoamiseen ja toimittamiseen.
        </p>

        <h3> 5. Miten tietoja suojataan ja säilytetään </h3>
        <p>Kaikki henkilötiedot ovat suojattu asiattomalta pääsyltä ja vahingossa tai laittomasti tapahtuvalta tietojen hävittämiseltä, muuttamiselta, luovuttamiselta, siirtämiseltä tai muulta laittomalta käsittelemiseltä. </p>

        <p> Aisto säilyttää henkilötietoja EU:n tai ETA:n sisällä. Järjestelmien sekä palvelinten tekninen tietoturva noudattaa hyviä tietoturvakäytäntöjä. Palvelimet on suojattu tietomurtoja ja palvelunestohyökkäyksiä vastaan. </p>

        <p> Henkilötietojen käsittelyssä ja teknisissä ratkaisuissa noudatamme hyviä tietosuojakäytäntöjä. </p>

        <p> Kaikkea pääsyä henkilötietoon valvotaan hyvien käytäntöjen mukaisesti. Vain rekisterinpitäjä käsittelee tietoja. </p>

        <h3> 6. Kuka tietoja käsittelee</h3>

        <p> Asiakastietoihin on pääsy vain rekisterinpitäjällä. Rekisterinpitäjä sitoutuu noudattamaan vaitiolovelvollisuutta ja pitämään salassa henkilötietojen käsittelyn yhteydessä saamansa tiedot.</p>

        <h3> 7. Tietojen säilyttäminen</h3>

        <p> Säilytämme henkilökohtaisia tietojasi vain tarvittavan ajan, jotta voimme täyttää selosteessa kuvatut käyttötarkoitukset. Tämän lisäksi joitain tietoja voidaan säilyttää kauemmin niiltä osin kuin se on tarpeen laissa asetettujen velvollisuuksien, kuten kirjanpitoa ja kuluttajakauppaa koskevien vastuiden, toteuttamiseksi ja niiden asianmukaisen toteuttamisen osoittamiseksi. </p>

        <p> Asiakkaan pyynnöstä häntä koskeva henkilötieto voidaan poistaa Aiston järjestelmistä. Poisto-toimenpide on peruuttamaton, emmekä voi palauttaa poistettuja henkilötietoja. </p>

        <h3> 8. Asiakkaan oikeudet</h3>

        <p> Asiakkaalla on oikeus: </p>

        <ul>
            <li> Saada jäljennös henkilötiedoista</li>
            <li> Tietyin edellytyksin pyytää käsittelyn rajoittamista tai vastustaa henkilötietojen käsittelyä</li>
            <li> Pyytää itseäsi koskevien henkilötietojen oikaisemista tai poistamista</li>
        </ul>
        <p>Lisäksi, jos käsittely perustuu erilliseen suostumukseen, asiakkaalla on mahdollisuus peruuttaa suostumus. Tämä ei kuitenkaan vaikuta ennen suostumuksen peruuttamista suoritettuun tietojen käsittelyn lainmukaisuuteen. Voit muuttaa suostumuksesi olemalla yhteydessä meihin. Pyynnön tulee olla riittävällä tavalla yksilöity, jotta voimme todentaa henkilöllisyytesi. Ilmoitamme sinulle, mikäli emme joiltain osin pysty toteuttamaan pyyntöäsi, kuten poistamaan kaikkia tietoja, joiden säilyttämiseen meillä on lakisääteinen velvollisuus tai oikeus.  </p>

        <h3> 9. Kuinka järjestelmään tallennetut tiedot saa</h3>

        <p> Voit pyytää Aiston järjestelmiin tallennetut tiedot itsellesi ottamalla yhteyttä meihin. Yhteystietomme löytyvät osoitteesta: http://www.aisto.fi/contact.</p>

        <h3> 10. Tietojen luovuttaminen kolmansille osapuolille </h3>

        <p> Asiakkaan suostumuksella tietoja voidaan luovuttaa yhteistyökumppaneille asiakkaan tilaamien tuotteiden ja palvelujen toimittamiseksi. Voimme luovuttaa joitakin välttämättömiä tietoja kolmansille osapuolille oikean ja laadukkaan palvelun takaamista varten. Tarvittaessa luovutamme tietoja myös viranomaisille. Informoimme tietopyynnöistä aina myös asiakasta, jos se on lain puitteissa sallittua.  </p>

        <p> Aisto huolehtii tietoja siirrettäessä ja käsiteltäessä tietoturvan ja -suojan korkeasta tasosta EU:n Tietosuoja-asetuksen mukaisesti. </p>

        <h3>11. Tietosuojaselosteen muuttaminen </h3>

        <p> Palveluiden kehityksen ja lainsäädännön muutosten johdosta pidätämme oikeuden muuttaa tietosuojaselostetta. Merkittävistä muutoksista tietosuojaselosteeseen ilmoitetaan rekisteröityneille asiakkaille ehtojen päivityksen yhteydessä. </p>
    </div>
  </Layout>
)

export default PrivacyPage
